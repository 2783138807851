:root {
  --plyr-font-size-base: 0.5em;
  --plyr-font-size-small: 1.1em;
  --plyr-font-size-large: 0.6em;
  --plyr-font-size-xlarge: 1.35em;
  --plyr-control-icon-size: 15px;
  --plyr-control-spacing: 12px;
  --plyr-control-padding: 2px;
  --plyr-control-radius: 1px;
  --plyr-color-main: #fff;
  --plyr-video-controls-background: rgba(0, 0, 0, 0.75);
  --plyr-control-toggle-checked-background: black;
}

.plyr__menu__container
  .plyr__control[role="menuitemradio"]:focus-visible::before,
.plyr__menu__container .plyr__control[role="menuitemradio"]:hover::before {
  background: rgba(0, 0, 0, 0.75) !important;
}

.plyr__control:focus-visible,
.plyr__control:hover,
.plyr__control:focus {
  background: none !important;
  position: relative;
  outline: 0 !important;
  box-shadow: none;
}

.plyr__control:focus-visible::before,
.plyr__control:hover::before,
.plyr__control:focus::before {
  content: "";
  position: absolute;
  inset: calc(-1 * 5px);
  border: solid 2px var(--plyr-color-main);
  border-radius: var(--plyr-control-radius);
}

.plyr__control:focus-visible:active::before,
.plyr__control:focus-visible.active::before,
.plyr__control:hover:active::before,
.plyr__control:hover.active::before,
.plyr__control:focus:active::before,
.plyr__control:focus.active::before {
  opacity: 0.75;
}

.plyr__control:focus-visible:disabled::before,
.plyr__control:focus-visible.disabled::before,
.plyr__control:hover:disabled::before,
.plyr__control:hover.disabled::before,
.plyr__control:focus:disabled::before,
.plyr__control:focus.disabled::before {
  display: none;
}

.plyr__control[role="menuitem"]:hover,
.plyr__control[data-plyr="language"]:hover {
  color: black !important;
}

.plyr__control.plyr__control--back:hover {
  color: black !important;
}

.plyr__controls {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.plyr__controls .plyr__progress__container:hover {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.plyr__controls .plyr__volume,
.plyr__controls .plyr__menu {
  margin-left: 11px !important;
}

.plyr__controls .plyr__volume .plyr__control,
.plyr__controls .plyr__menu .plyr__control {
  padding: 2px !important;
}

.plyr__controls .plyr__menu .plyr__control[aria-expanded="true"] {
  background: none !important;
}

.plyr__controls .plyr__controls__item:not(.plyr__volume):not(.plyr__menu) {
  margin-left: 11px !important;
  padding: 2px !important;
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding: 0 2px !important;
}

.plyr__controls .plyr__controls__item:focus-visible,
.plyr__controls .plyr__controls__item:hover,
.plyr__controls .plyr__controls__item:focus {
  background: none !important;
  position: relative;
  outline: 0 !important;
  box-shadow: none;
}

.plyr__controls .plyr__controls__item:focus-visible::before,
.plyr__controls .plyr__controls__item:hover::before,
.plyr__controls .plyr__controls__item:focus::before {
  content: "";
  position: absolute;
  inset: calc(-1 * 5px);
  border: solid 2px var(--plyr-color-main);
  border-radius: var(--plyr-control-radius);
}

.plyr__controls .plyr__controls__item:focus-visible:active::before,
.plyr__controls .plyr__controls__item:focus-visible.active::before,
.plyr__controls .plyr__controls__item:hover:active::before,
.plyr__controls .plyr__controls__item:hover.active::before,
.plyr__controls .plyr__controls__item:focus:active::before,
.plyr__controls .plyr__controls__item:focus.active::before {
  opacity: 0.75;
}

.plyr__controls .plyr__controls__item:focus-visible:disabled::before,
.plyr__controls .plyr__controls__item:focus-visible.disabled::before,
.plyr__controls .plyr__controls__item:hover:disabled::before,
.plyr__controls .plyr__controls__item:hover.disabled::before,
.plyr__controls .plyr__controls__item:focus:disabled::before,
.plyr__controls .plyr__controls__item:focus.disabled::before {
  display: none;
}

.plyr__control--overlaid {
  background: rgba(0, 0, 0, 0.75) !important;
}

.plyr__control--overlaid:hover {
  background: rgba(0, 0, 0, 0.95) !important;
}
